import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import { Task } from "@mui/icons-material";
import axiosInstance from "../../utils/axios";
import { useSnackbar } from "../SnackbarContext";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 600,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

export default function KeywordModal({ open, setOpen, project_id }) {
	// const [open, setOpen] = React.useState(false);
	const [file, setFile] = React.useState();
	const handleClose = () => setOpen(false);
	const setSnackState = useSnackbar();

	const handleAddKeyword = async () => {
		const formData = new FormData();

		formData.append("file", file);

		try {
			const resp = await axiosInstance.post(
				`/infopulse/projects/${project_id}/keyword/`,
				formData
			);
			console.log(resp.data);
			setOpen(false);
			setSnackState({
				status: 200,
				message: "Keyword Added",
			});
		} catch (error) {
			setOpen(false);
			console.log(error.response);
		}
	};

	const downloadFormatHandler = async () => {
		try {
			const resp = await axiosInstance.get(
				"/infopulse/projects/keyword/get_upload_file",
				{ responseType: "blob" }
			);
			const href = URL.createObjectURL(resp.data);
			const link = document.createElement("a");
			link.href = href;
			link.setAttribute("download", `keyword_file.xlsx`);
			document.body.appendChild(link);
			link.click();

			// clean up "a" element & remove ObjectURL
			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch (error) {
			console.log(error.response);
		}
	};

	return (
		<>
			{/* <Box className="bt_button alltxfield" style={{ display: "flex" }}>
        <Button
          variant="contained"
          color="primary"
          className="g_bttn bt"
          size="small"
          onClick={handleOpen}
        >
          Open Keyword Uploader
        </Button>
      </Box> */}

			<div>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						<Box mt={2} mb={4} style={{ display: "flex", background: "grey" }}>
							<IconButton>
								<Task />
							</IconButton>

							<Typography id="modal-modal-title" variant="h6" component="h2">
								Upload Keywords
							</Typography>
						</Box>
						<Box mt={2} mb={2}>
							<Button
								variant="contained"
								type="button"
								size="large"
								onClick={downloadFormatHandler}
							>
								Download Format
							</Button>
						</Box>
						<Box>
							<Typography>Upload Excel File</Typography>
						</Box>
						<Box mt={2} mb={2}>
							<Box className="alltxfield uploadfile">
								<input
									type="file"
									name=""
									id="uploadFile"
									accept={".xlsx"}
									className="input-file"
									data-test="file-upload"
									onChange={(e) => setFile(e.target.files[0])}
								/>
							</Box>
						</Box>
						<Box mt={4} mb={2}>
							<Button
								size="large"
								variant="contained"
								color="success"
								onClick={handleAddKeyword}
								className="r_bttn bt"
							>
								Submit
							</Button>
						</Box>
					</Box>
				</Modal>
			</div>
		</>
	);
}
