import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

const initialState = [];

export const createProject = createAsyncThunk(
	"projects/create",
	async ({ data }) => {
		const res = await axiosInstance.post("/infopulse/projects/", data, {
			headers: {
				Authorization: `Bearer `,
			},
		});
		return res.data;
	}
);

export const getAllProjects = createAsyncThunk("projects/all", async () => {
	const res = await axiosInstance.get("/infopulse/projects/", {
		headers: {
			Authorization: `Bearer `,
		},
	});
	return res.data;
});

export const getProject = createAsyncThunk(
	"project/get",
	async ({ project_code }) => {
		const res = await axiosInstance.get(
			`/infopulse/projects/${project_code}/`,
			{
				headers: {
					Authorization: `Bearer `,
				},
			}
		);
		return res.data;
	}
);

export const updateProject = createAsyncThunk(
	"project/update",
	async ({ project_code, data }) => {
		const res = await axiosInstance.put(
			`/infopulse/projects/${project_code}/`,
			data
		);
		return res.data;
	}
);

export const deleteProject = createAsyncThunk(
	"project/delete",
	async ({ project_code }) => {
		const res = await axiosInstance.delete(
			`/infopulse/projects/${project_code}/`
		);
		return res.data;
	}
);

const projectSlice = createSlice({
	name: "project",
	initialState: initialState,
	extraReducers: {
		[createProject.fulfilled]: (state, action) => {
			state.push(action.payload);
		},
		[getAllProjects.fulfilled]: (state, action) => {
			return [...action.payload];
		},
	},
});

export default projectSlice.reducer;
