import React from "react";
import { Redirect } from "react-router-dom";
import { ApmRoute } from "@elastic/apm-rum-react";
import useAuth from "../hooks/useAuth";

function AuthProtect({ component: Component, ...rest }) {
  const { isAuthenticated } = useAuth();
  return (
    <ApmRoute
      exact
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default AuthProtect;
