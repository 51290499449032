import React from "react";
import "./App.css";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Router } from "react-router-dom";
// import { BrowserRouter as Router } from "react-router-dom";

import { renderRoutes } from "./routes";
import { createBrowserHistory } from "history";
import { Cookies } from "react-cookie";
import axiosInstanceIDM from "./utils/axiosInstanceIDM";
import jwt from "jwt-decode";
import { useEffect } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, logoutSuccess } from "./redux/slices/authJwt";
import { persistor } from "./redux/store";
import AuthVerify from "./components/AuthVerify";
import { SnackbarProvider } from "./components/SnackbarContext";
import axiosInstance from "./utils/axios";

const history = createBrowserHistory();
const cookies = new Cookies();

export const SnackbarContext = React.createContext();

function App() {
	const dispatch = useDispatch();
	const { token } = useSelector((state) => state.authJwt);

	useEffect(() => {
		if (token) {
			localStorage.setItem("token", token);
			axiosInstance.defaults.headers.common["Authorization"] =
				"Bearer " + token;
			axiosInstanceIDM.defaults.headers.common["Authorization"] =
				"Bearer " + token;
		}
	}, [token]);

	useEffect(() => {
		if (token === null) {
			console.log("LOGOUT>>>");
			// dispatch(logoutSuccess());
			axiosInstanceIDM.defaults.headers.common["Authorization"] = null;
			axiosInstance.defaults.headers.common["Authorization"] = null;
			localStorage.clear();
			cookies.remove("__idm_sec", {
				domain: process.env.REACT_APP_COOKIE_DOMAIN,
				path: "/",
			});
		}
	}, []);

	const logOut = () => {
		console.log("LOGOUT>>>");
		// dispatch(logoutSuccess());
		localStorage.clear();
		cookies.remove("__idm_sec", {
			domain: process.env.REACT_APP_COOKIE_DOMAIN,
			path: "/",
		});
	};

	return (
		<>
			<PersistGate loading={null} persistor={persistor}>
				<Router history={history}>
					<SnackbarProvider>
						<Header />
						{renderRoutes()}
						<AuthVerify logOut={logOut} />
					</SnackbarProvider>
				</Router>
			</PersistGate>
		</>
	);
}

export default App;
